import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"
import axios from 'axios'
import VueAxios from 'vue-axios'

//import Home from './components/HelloWorld.vue'
const Home = ()=> import('./components/HelloWorld.vue')

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false


const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Home
  },
]

const router = new VueRouter({
  mode: "history",
  routes
});



new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
